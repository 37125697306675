import React, { useState } from 'react'
import styled from 'styled-components'
import { FixedSizeList as List } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

import SearchInputWithCounter from '../../../commons/components/SearchInputWithCounter'
import { Label } from '../../../commons/commonStyles'
import { Card } from './Card'

export function NonAssociatedClients({
    handleSelectClient,
    nonAssociatedClientsTotal,
    nonAssociatedClients,
    handleFilter,
}) {
    const [filter, setFilter] = useState('')
    const itemsCount = Object.keys(nonAssociatedClients).length

    const handleFilterChange = ({ target }) => {
        setFilter(target.value)
        handleFilter({
            target,
            association: 'nonAssociatedClient',
        })
    }

    return (
        <>
            <ClientsHeader>
                <Label>Clientes não associados</Label>
            </ClientsHeader>
            <SearchInputWithCounter
                placeholder={'Buscar cliente'}
                value={filter}
                onChange={handleFilterChange}
                total={nonAssociatedClientsTotal}
                selected={
                    Object.values(nonAssociatedClients).filter(
                        ({ isSelected }) => isSelected
                    ).length
                }
            />
            <ClientsListContainer>
                <InfiniteLoader
                    isItemLoaded={() => true}
                    loadMoreItems={() => {}}
                    itemCount={itemsCount}
                >
                    {({ onItemsRendered, ref }) => (
                        <List
                            className="List"
                            height={296}
                            itemCount={itemsCount}
                            itemSize={40}
                            onItemsRendered={onItemsRendered}
                            ref={ref}
                        >
                            {props =>
                                Card({
                                    ...props,
                                    items: nonAssociatedClients,
                                    association: nonAssociatedClients,
                                    name: 'nonAssociatedClientCard',
                                    onClick: handleSelectClient,
                                })
                            }
                        </List>
                    )}
                </InfiniteLoader>
            </ClientsListContainer>
        </>
    )
}

const ClientsHeader = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
`

const ClientsListContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1.3vh;
    width: 100%;
    height: 296px;
    flex-grow: 0;
    overflow: auto;
    border: 1px solid #dadada;
    border-radius: 4px;
    padding-top: 0.5vh;
    font-family: Roboto;
`
